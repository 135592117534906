import { ApiVersion, Endpoint, SearchQuery } from '@eobuwie/search';

import {
    MEGATRON_SEARCH_GET_AUTOCOMPLETE_OPERATION_NAME,
    MEGATRON_SEARCH_GET_CATEGORIES_OPERATION_NAME,
} from '@configs/megatron-client';
import { SEARCH_API_BRAND_CHANNEL } from '@configs/x3/config-keys';

import { getMegatronEndpointParameter } from '@assets/get-megatron-endpoint-parameter-search';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const AUTOCOMPLETE_RESULTS_LIMIT = 4;
const CATEGORIES_RESULTS_LIMIT = 2000;
const ERROR_TAGS = {
    [ERROR_ACTION_TAG_NAME]: 'services.search.getAutocomplete',
};

const emptyAutocompleteResponse = () => ({
    attributes: {},
    categories: [],
    manufacturers: [],
    products: [],
});

const emptyCategoriesResponse = () => ({
    items: [],
    page: 0,
    per_page: 0,
    total: 0,
});

export default ({
    megatronClient,
    normalizeMegatronResponse,
    $errorHandler,
    $configProvider,
    helpers: { abTests },
}) => ({
    async getAutocomplete(pimContextCode, query, locale, currency) {
        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.AUTOCOMPLETE2,
            });

            const searchQueryUrl = searchQuery
                .setChannel($configProvider.getConfig(SEARCH_API_BRAND_CHANNEL))
                .setCurrency(currency)
                .setLocale(locale)
                .setLimit(AUTOCOMPLETE_RESULTS_LIMIT)
                .setQuery(query)
                .setPimContextCode(pimContextCode.toString())
                .build();

            const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH = getMegatronEndpointParameter(abTests);

            const response = await megatronClient.get(searchQueryUrl, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH,
            });

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_AUTOCOMPLETE_OPERATION_NAME
            );

            if (!error) {
                return data;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );

            return emptyAutocompleteResponse();
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return emptyAutocompleteResponse();
        }
    },

    async getCategories(locale) {
        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.CATEGORIES,
            });

            const searchQueryUrl = searchQuery
                .setChannel($configProvider.getConfig(SEARCH_API_BRAND_CHANNEL))
                .setLocale(locale)
                .setLimit(CATEGORIES_RESULTS_LIMIT)
                .build();

            const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH = getMegatronEndpointParameter(abTests);

            const response = await megatronClient.get(searchQueryUrl, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH,
            });

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_CATEGORIES_OPERATION_NAME
            );

            if (!error) {
                return data;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );

            return emptyCategoriesResponse();
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return emptyCategoriesResponse();
        }
    },
});
