import { MEGATRON_SEARCH_GET_STORE_OPERATION_NAME } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { SearchQuery, ApiVersion, Endpoint } from '@eobuwie/search';

import { snakeCaseToCamelCaseObjectKeys } from '@assets/object';
import { getMegatronEndpointParameter } from '@assets/get-megatron-endpoint-parameter-search';

const MAX_CACHED_SELLERS_COUNT = 10;

const pushSellerToCache = (cache, keys, seller) => {
    cache[seller.id] = seller;
    keys.push(seller.id);

    if (keys.length <= MAX_CACHED_SELLERS_COUNT) {
        return;
    }

    delete cache[keys.shift()];
};

const GET_BY_ID_ERROR_TAGS = {
    [ERROR_ACTION_TAG_NAME]: 'services.sellers.getById',
};

export default ({
    megatronClient,
    normalizeMegatronResponse,
    $errorHandler,
    helpers: { abTests },
}) => ({
    keys: [],

    cache: {},

    async getById(id) {
        const { cache, keys } = this;

        const cachedSeller = cache[id];

        if (cachedSeller) {
            return cachedSeller;
        }

        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.STORES,
            });

            const searchQueryUrl = searchQuery.setUrlKey(id).build();

            const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH = getMegatronEndpointParameter(abTests);

            const response = await megatronClient.get(searchQueryUrl, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH,
            });

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_STORE_OPERATION_NAME
            );

            if (error) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    GET_BY_ID_ERROR_TAGS,
                    {
                        searchQueryUrl,
                        data,
                        error,
                        status,
                    }
                );

                return null;
            }

            if (!data) {
                return null;
            }

            const normalizedSeller = snakeCaseToCamelCaseObjectKeys(data);

            pushSellerToCache(cache, keys, normalizedSeller);

            return normalizedSeller;
        } catch (err) {
            $errorHandler.captureError(err, GET_BY_ID_ERROR_TAGS);

            return null;
        }
    },
});
